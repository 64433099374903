import { Component, ViewChild } from '@angular/core';
import { Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { CropperPosition, ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { APP_CONFIG } from 'src/app/commom/constants';
import { TipoImagem } from 'src/app/enums/tipo-imagem.enum';
import { Arquivo, CropPosition } from 'src/app/models/arquivo.model';

declare let jscanify: any;
declare let cv: any;
declare let tracking: any;

@Component({
	selector: 'app-modal-foto-preview',
	templateUrl: './modal-foto-preview.page.html',
	styleUrls: ['./modal-foto-preview.page.scss'],
})
export class ModalFotoPreviewPage {

	@ViewChild('cropper') cropper: ImageCropperComponent;

	cropPosition: CropperPosition = { x1: 0, x2: APP_CONFIG.IMAGE_SIZE.WIDTH, y1: 0, y2: APP_CONFIG.IMAGE_SIZE.HEIGHT };
	documentCornerPoints: CropPosition;

	title: string = "";
	picture: string = "";
	recortarImagem: boolean;
	confirmarImagem: boolean;
	tipoImagem: TipoImagem;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private platform: Platform
	) {
		this.prepararImagem();
	}

	private async prepararImagem(): Promise<void> {
		try {
			this.title = this.navParams.get('title') || '';
			this.tipoImagem = this.navParams.get("tipoImagem") || TipoImagem.DOCUMENTO;
			this.recortarImagem = this.navParams.get('recortarImagem');
			const arquivo: any = this.navParams.get('picture');
			console.log(arquivo);
			if (arquivo && arquivo instanceof Arquivo) {
				if (this.platform.is("capacitor")) {
					const file: ReadFileResult = await Filesystem.readFile({ path: arquivo.urlNativo });
					this.picture = "data:image/png;base64," + file.data;
				} else {
					const reader: FileReader = new FileReader();
					reader.addEventListener('load', (event) => {
						this.picture = event.target.result as string;
					});
					reader.readAsDataURL(arquivo.pwaFile);
				}
			} else {
				this.picture = arquivo;
			}
		} catch (error) {
			console.error(error);
		}
	}

	dismiss(): void {
		this.modalCtrl.dismiss();
		this.liberarMemoria();
	}

	aprovar(): void {
		if (this.recortarImagem) {
			this.cropper.crop();
		} else {
			this.modalCtrl.dismiss({ picture: this.picture });
		}
	}

	private liberarMemoria(): void {
		try {
			URL.revokeObjectURL(this.picture);
		} catch (error) {
			console.error(error);
		}
	}


	// CROPPER
	// --------------------------

	imageCropped(event: ImageCroppedEvent) {
		console.log(event);
		this.modalCtrl.dismiss({ picture: event.base64 });
		// Para testes
		// this.mostrarModalPreviewFoto(event.base64);
	}

	imageLoaded(image: LoadedImage) {
		console.log(image);
	}
	
	async cropperReady() {
		console.log("cropperReady");

		if (!this.recortarImagem) {
			return;
		}
		if (this.tipoImagem == TipoImagem.DOCUMENTO) {
			const scanner = new jscanify();
			// const highlightedCanvas: HTMLCanvasElement = scanner.highlightPaper(image);
			// console.log(highlightedCanvas);
			// const base64 = highlightedCanvas.toDataURL("image/png");
			// this.mostrarModalPreviewFoto(base64);

			const contour = scanner.findPaperContour(cv.imread(this.cropper.sourceImage.nativeElement));
			this.documentCornerPoints = scanner.getCornerPoints(contour);

			const x1: number = this.documentCornerPoints.topLeftCorner.x < this.documentCornerPoints.bottomLeftCorner.x ? this.documentCornerPoints.topLeftCorner.x : this.documentCornerPoints.bottomLeftCorner.x;
			const x2: number = this.documentCornerPoints.topRightCorner.x > this.documentCornerPoints.bottomRightCorner.x ? this.documentCornerPoints.topRightCorner.x : this.documentCornerPoints.bottomRightCorner.x;
			const y1: number = this.documentCornerPoints.topLeftCorner.y < this.documentCornerPoints.topRightCorner.y ? this.documentCornerPoints.topLeftCorner.y : this.documentCornerPoints.topRightCorner.y;
			const y2: number = this.documentCornerPoints.bottomLeftCorner.y > this.documentCornerPoints.bottomRightCorner.y ? this.documentCornerPoints.bottomLeftCorner.y : this.documentCornerPoints.bottomRightCorner.y;
			
			this.cropPosition = { 
				x1: x1, 
				x2: x2, 
				y1: y1, 
				y2: y2 
			};
		} else {
			var tracker = new tracking.ObjectTracker("face");	  
			tracking.track(this.cropper.sourceImage.nativeElement, tracker);
			tracker.on('track', async (event) => {
				if (event.data && event.data.length > 0) {
					const rect = event.data[0];
					const margin: number = 70;

					this.cropPosition = { 
						x1: rect.x - margin, 
						x2: rect.x + rect.width + margin, 
						y1: rect.y - margin, 
						y2: rect.y + rect.height + margin
					};

					console.log(this.cropPosition);
					event.data.forEach((rect) => {
						console.log(rect);
					});
				}
			});
		}
		console.log(this.cropPosition);
	}

	loadImageFailed() {
		// show message
		console.log("loadImageFailed");
	}

	// Usado para testes
	private async mostrarModalPreviewFoto(base64: string): Promise<void> {
		const modal: HTMLIonModalElement = await this.modalCtrl.create({
			component: ModalFotoPreviewPage,
			componentProps: {
				picture: base64,
				recortarImagem: false,
				title: "Resultado"
			}
		});
		modal.present();
	}
}
