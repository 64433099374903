import { GrupoDados } from "../enums/grupo-dados.enum";
import { Arquivo } from "./arquivo.model";

export class Documento {
	codigo: string;
	descricao: string;
	grupo: GrupoDados;
	obrigatorio?: "S" | "N";
	precisaPreencher?: "S" | "N";
	tipoCertidao?: string;
	arquivo?: Arquivo;
	caminhoArquivoNativo?: string;
	cpfDependenteParaIncluir?: string;
	base64?: string;

	constructor(grupo: GrupoDados, obj: any = { }) {
		Object.assign(this, obj);
		this.grupo = grupo;

		if (!this.obrigatorio) {
			this.obrigatorio = "S";
		}
	}
}
