import { environment } from 'src/environments/environment';

export const END_POINT: string = document.URL.indexOf("http://localhost:81") == 0 ? environment.serviceConfig.proxy : environment.serviceConfig.path;
export const AUTH_END_POINT: string = document.URL.indexOf("http://localhost:81") == 0 ? environment.authConfig.proxy : environment.authConfig.path;
// export const MATCH_END_POINT: string = document.URL.indexOf("http://localhost:81") == 0 ? environment.matchConfig.proxy : environment.matchConfig.path;

const END_POINT_PAC: string = END_POINT + (environment.mode == "DEV" ? "prf/rest/" : "prprev/pacrh/");
const END_POINT_CCHEQUE: string = END_POINT + (environment.mode == "DEV" ? "" : "prprev/cchequenet/");
const END_POINT_CELEPAR: string = environment.mode == "DEV" ? "/proxy/hml-service/" : END_POINT;
const END_POINT_MATCH: string = END_POINT + "prprev/bf/";

// tslint:disable-next-line: typedef
export const WEBSERVICE = {
	ULTIMO_CONTRACHEQUE: END_POINT_CCHEQUE + "ws/v1/SelecionarUltimaRemuneracao.ashx",
	CONTRACHEQUE_PDF: END_POINT_CCHEQUE + "ws/v1/GerarContracheque.ashx?",
	CONTRACHEQUE_POR_DATA: END_POINT_CCHEQUE + "ws/v1/ListarContrachequesMes.ashx?",
	OBTER_DATAS_CONTRACHEQUE: END_POINT_CCHEQUE + "ws/v1/SelecionarDatasContracheque.ashx",
	ANOS_RENDIMENTOS: END_POINT_PAC + "comprovanterendimentos/v1/recuperaanos",
	COMP_RENDIMENTOS: END_POINT_PAC + "comprovanterendimentos/v1/recuperacomprovante/", //{cpf}/{ano}
	// VERIFICA_SERVIDOR: END_POINT_PAC + "tiposervidor/v1/ehservidor/", //{cpf}
	// VERIFICA_PENSIONISTA: END_POINT_PAC + "tiposervidor/v1/ehaposentadopens/", //{cpf}
	VERIFICA_RECADASTRAMENTO: END_POINT_PAC + "tiposervidor/v1/poderecadastrar/", //{cpf}
	// VERIFICA_APOSENTADO: END_POINT_PAC + "tiposervidor/v1/ehaposentado/", //{cpf}
	DADOS_CADASTRO: END_POINT_PAC + "recadsimpl/v1/obterdados/", //{cpf}
	ATUALIZACAO_DADOS: END_POINT_PAC + "recadsimpl/v1/inserirdados",
	OBTER_PERFIL: END_POINT_PAC + "tiposervidor/v1/retornadadosserv/", //{cpf}
	BUSCA_RG: END_POINT_PAC + "recadastramento/v1/retornainformacoesrg/",

	BUSCA_ENDERECO: END_POINT_CELEPAR + "celepar/neocep/enderecamento/v3/endereco/", //{cep}/2
	BUSCA_LOCALIDADE: END_POINT_CELEPAR + "celepar/neocep/enderecamento/v3/localidade/", //{chave_localidade}/2/2
	BUSCA_MUNICIPIOS_UF: END_POINT_CELEPAR + "celepar/neocep/enderecamento/v3/localidades/{uf}/M/2",

	// OBTER_TIPO_SERVIDOR: END_POINT_PAC + "tiposervidor/v1/tiposervidor/",

	// RECADASTRAMENTO
	OBTER_DADOS_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/obterdados/{cpf}",
	OBTER_RESUMO_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/retornaresumorecad/{cpf}",
	LISTAR_ESTADO_CIVIL: END_POINT_PAC + "recadastramento/v1/listarestadocivil",
	OBTER_OPERACOES_DEPENDENTE: END_POINT_PAC + "recadastramento/v1/listaroperacoesdependente/{cpf}/{estadocivilantigo}/{estadocivilnovo}",
	// DOCUMENTOS_PENSIONISTA: END_POINT_PAC + "recadastramento/v1/listadocinstpens/{cpf}/{alterouEstadoCivil}?receberenda={receberenda}",
	OBTER_DOCUMENTOS: END_POINT_PAC + "recadastramento/v1/listadocinstrecad/{cpf}/{alterouEstadoCivil}",
	INSERIR_DADOS_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/inserirdados",
	FINALIZAR_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/finalizarrecadastramento",
	GEROU_EPROTOCOLO: END_POINT_PAC + "recadastramento/v1/geraeprotocolo/{cpf}",
	MOSTRAR_PERGUNTA_RECEBE_RENDA: END_POINT_PAC + "recadastramento/v1/mostraperguntareceberenda/{cpf}",

	TIPOS_REPRESENTANTE_LEGAL: END_POINT_PAC + "representantelegal/v1/listartiposrepresentante",
	DOCUMENTOS_REPRESENTANTE_LEGAL: END_POINT_PAC + "representantelegal/v1/obterdocrepresentante/{tiporepresentante}",
	OBTER_DADOS_REPRESENTANTE_LEGAL: END_POINT_PAC + "representantelegal/v1/obterdados/{cpf}",
	INSERIR_DADOS_REPRESENTANTE_LEGAL: END_POINT_PAC + "representantelegal/v1/inserirdados",

	OBTER_VINCULOS_DEPENDENCIA: END_POINT_PAC + "dependente/v1/listarvinculosdependencia/{cpf}/{teladependente}",
	LISTA_ESTADO_CIVIL_DEPENDENTES: END_POINT_PAC + "dependente/v1/listarestadocivil/{vinculodependencia}",
	DOCUMENTOS_INCLUSAO_DEPENDENTE: END_POINT_PAC + "dependente/v1/obterdocincldep/{tipoDependente}/{rgDoParana}",
	OBTER_MOTIVOS_EXCLUSAO_DEPENDENTE: END_POINT_PAC + "dependente/v1/obtermotivofimdep/{tipoDependente}",
	DOCUMENTOS_EXCLUSAO_DEPENDENTE: END_POINT_PAC + "dependente/v1/obterdocexcldep/{motivo}",
	VALIDAR_ALTERACOES_DEPENDENTE: END_POINT_PAC + "dependente/v1/validadependente",
	VALIDAR_EXCLUSAO_DEPENDENTE: END_POINT_PAC + "dependente/v1/validaexclusaodependente",
	OBTER_DADOS_DEPENDENTE: END_POINT_PAC + "dependente/v1/obterdados/{cpf}",
	INSERIR_DADOS_DEPENDENTE: END_POINT_PAC + "dependente/v1/inserirdados",

	ANEXAR_DOCUMENTO_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/anexardocumento/{catalogo}/{codigodocumento}",
	ANEXAR_DOCUMENTO_DEPENDENTE: END_POINT_PAC + "dependente/v1/anexardocumento/{catalogo}/{codigodocumento}",
	ANEXAR_DOCUMENTO_REPRESENTANTE_LEGAL: END_POINT_PAC + "representantelegal/v1/anexardocumento/{catalogo}/{codigodocumento}",
	VERIFICA_SE_NECESSARIO_ENVIAR_DOCUMENTOS_EPROTOCOLO: END_POINT_PAC + "recadastramento/v1/geraprotocolo/{catalogo}",
	OBTER_PENDENCIAS: END_POINT_PAC + "recadastramento/v1/listardocumentopendente/{cpf}",

	LISTAR_DECLARACAO: END_POINT_PAC + "recadastramento/v1/listardeclaracao/{cpf}/{recebeRenda}",

	//MATCH
	MATCH: END_POINT_MATCH + "matchweb/v2",

	// HISTORICO
	LISTAR_ANOS_HISTORICO: END_POINT_PAC + "recadastramento/v1/listaanos/", //{cpf}
	HISTORICO_RECADASTRAMENTO: END_POINT_PAC + "recadastramento/v1/historico/", //{cpf}/{ano}
	HISTORICO_COMPLETO: END_POINT_PAC + "recadastramento/v1/historicocompleto/", //{cpf}/{ano}

	// MENSAGENS
	OBTER_MENSAGENS: END_POINT_PAC + "mensageria/v1/listarmensagens/PRPREV",
	OBTER_TOTAL_MENSAGENS_NAO_LIDAS: END_POINT_PAC + "mensageria/v1/totalnaolida/PRPREV",
	MARCAR_MENSAGEM_LIDA: END_POINT_PAC + "mensageria/v1/marcarmensagemlida/PRPREV",
	
	// PUSH
	CADASTRAR_DEVICE_PUSH: END_POINT_PAC + "device/v1/cadastrar/",
	REVOGAR_DEVICE_PUSH: END_POINT_PAC + "device/v1/revogar/PRPREV/{token}"
};


export const APP_CONFIG = {
	WEB_VERSION: "1.9.0 (102)",
	// Esses termos podem ser substituidos em tempo de execução através do firebase
	TERMOS_USO: {
		versao: 2,
		urlTermo: "https://www.documentador.pr.gov.br/documentador/pub.do?action=d&uuid=@gtf-escriba-prprev@c1091186-2066-407f-a666-69b309f8bc61",
		urlPrivacidade: "https://pia.paas.pr.gov.br/termos/app/prprev/politica_privacidade"
	},
	APPS: {
		ios: "id1543418034",
		android: "br.gov.pr.celepar.seap.paranaprev"
	},
	ERROS: {
		TRATADO_APP: 1,
		FALHA_ENVIO_DOC: 2
	},
	LINKS: {
		EDUCACAO_PREVIDENCIARIA: "https://www.educacaoprevidenciaria.pr.gov.br/"
	},
	IMAGE_SIZE: {
		WIDTH: 1400,
		HEIGHT: 1400
	}
};
