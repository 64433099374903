import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { ModalFotoPreviewPage } from './modal-foto-preview.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ImageCropperModule,
		PinchZoomModule
	],
	declarations: [ModalFotoPreviewPage]
})
export class ModalFotoPreviewPageModule { }
