import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePtBR from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';
import { BrMaskDirective } from 'br-mask';
import { EventService } from 'src/services/event.service';
import { MensagemService } from 'src/services/mensagem.service';
import { ParanaPrevService } from 'src/services/paranaprev.service';
import { RemoteService } from 'src/services/remote.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalFotoPreviewPageModule } from './pages/camera/modal-foto-preview/modal-foto-preview.module';
import { ModalHelperPage } from './pages/modal-helper/modal-helper.page';
import { ModalPendenciasPageModule } from './pages/modal-pendencias/modal-pendencias.module';
import { ModalPesquisaPageModule } from './pages/modal-pesquisa/modal-pesquisa.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { ModalNotificacaoPageModule } from './pages/modal-notificacao/modal-notificacao.module';


//https://angular.io/guide/i18n#i18n-pipes
registerLocaleData(localePtBR);
@NgModule({
	declarations: [AppComponent, ModalHelperPage],
	entryComponents: [ModalHelperPage],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			backButtonText: ''
		}),
		AppRoutingModule,
		HttpClientModule,
		ModalFotoPreviewPageModule,
		ModalPendenciasPageModule,
		ModalPesquisaPageModule,
		ModalNotificacaoPageModule,
		ServiceWorkerModule.register('combined-sw.js', { enabled: !isPlatform("capacitor") && location.hostname != "localhost" }), //environment.production
		AngularFireModule.initializeApp(environment.firebaseConfig),
    	AngularFireMessagingModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		AppVersion,
		Device,
		File,
		FileOpener,
		Market,
		Network,
		HTTP,
		BrMaskDirective,
		InAppBrowser,
		EventService,
		RemoteService,
		MensagemService,
		ParanaPrevService,
		SocialSharing,
		WebView,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LOCALE_ID, useValue: "pt" }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
