// tslint:disable-next-line: typedef
export const environment = {
	production: false,
	mode: "HML",
	authConfig: {
		params: {
			response_type: 'code',
			client_id: '31b3b31a1c2f8a370206f111127c0dbd',
			redirect_uri: 'http://localhost:8100/login',
			redirect_uri_pwa: 'https://homologacao.paranaprev.pr.gov.br/paranaprev/login'
		},
		path: 'https://auth-cs-hml.identidadedigital.pr.gov.br/centralautenticacao/api/v1/',
		proxy: '/proxy/auth-hml-service/'
	},
	serviceConfig: {
		params: {
			consumer_id: 'APP_PRPREV'
		},
		proxy: '/proxy/hml-service/',
		path: 'https://hml-apigateway.paas.pr.gov.br/'
	},
	globalParams: {
		cpfTesteApple: "38116990080" // utilizado para teste na Apple
	},
	firebaseConfig: {
		apiKey: "AIzaSyDYy09x1RW7dBCM2NKReFklucwgMijnBt0",
		authDomain: "prprev-app.firebaseapp.com",
		projectId: "prprev-app",
		storageBucket: "prprev-app.appspot.com",
		messagingSenderId: "424282320685",
		appId: "1:424282320685:web:5115746e8a1d5985a76da4",
		measurementId: "G-73V2H5EM9E"
	}
};
