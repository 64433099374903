export class Arquivo {
	name: string;
	type: string;
	pwaFile: File;
	urlNativo: string;
}

export interface CropPosition {
	topLeftCorner: Position;
	topRightCorner: Position;
	bottomLeftCorner: Position;
	bottomRightCorner: Position;
}

export interface Position {
	x: number;
	y: number;
}